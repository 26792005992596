import React from "react";
import { useEffect, useState } from "react";

const Progressbar = () => {
    const [scroll, setScroll] = useState(0);

    const onscroll = () => {
        const totalScroll = document.documentElement.scrollTop;
        const windowHeight =
            document.querySelector(".blog-detail").scrollHeight -
            document.documentElement.clientHeight;
        const scroll = (totalScroll / windowHeight) * 100;

        if (window.scrollY > 500) {
            document.querySelector(".progressbar").classList.add("active");
        }

        setScroll(scroll);
    };

    useEffect(() => {
        window.addEventListener("scroll", onscroll);
        return () => window.removeEventListener("scroll", onscroll);
    }, [scroll]);
    return <div className="progressbar" style={{ width: `${scroll}%` }}></div>;
};

export default Progressbar;
