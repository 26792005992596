import React from "react";
import { BLOCKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Lottie from "react-lottie";
import InViewLogoAnimationController from "../components/InViewLogoAnimationController";
import slugify from "react-slugify";

const useResolveBlogText = (rawHTML) => {
    const options = {
        renderNode: {
            [BLOCKS.HEADING_1]: (node, children) => {
                return <h1>{children}</h1>;
            },
            [BLOCKS.HEADING_2]: (node, children) => {
                return <h2 className="text-h2 topic-title text-fw-medium">{children}</h2>;
            },
            [BLOCKS.HEADING_3]: (node, children) => {
                return <h3 className="text-h3 text-clr-primary text-fw-medium">{children}</h3>;
            },
            [BLOCKS.HEADING_4]: (node, children) => {
                return <h4 className=" text-clr-primary text-fw-medium">{children}</h4>;
            },
            [BLOCKS.HEADING_5]: (node, children) => {
                return <h5 className=" text-clr-primary text-fw-medium">{children}</h5>;
            },
            [BLOCKS.HEADING_6]: (node, children) => {
                return <h6 className=" text-clr-primary text-fw-medium">{children}</h6>;
            },
            // [BLOCKS.EMBEDDED_ASSET]: (node) => {
            //     const { gatsbyImageData, title, file } = node.data.target;

            //     if (node.data.target.file.contentType === "image/png") {
            //         return <GatsbyImage image={getImage(gatsbyImageData)} alt="img" />;
            //     }
            // },
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                const { gatsbyImageData, title, file } = node.data.target;

                if (node.data.target) {
                    if (file.contentType === "video/mp4")
                        return (
                            <InViewLogoAnimationController animationName={"play"}>
                                <video controls>
                                    <source src={file.url} type={file.contentType} />
                                </video>
                            </InViewLogoAnimationController>
                        );

                    if (file.contentType === "application/json")
                        return (
                            <div className="lottie-player-wrap">
                                <Lottie
                                    className="lottie-player"
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        path: file.url,
                                        rendererSettings: {
                                            preserveAspectRatio: "xMidYMid slice",
                                        },
                                    }}
                                />
                            </div>
                        );

                    return <GatsbyImage image={getImage(gatsbyImageData)} alt={title} />;
                }
            },
        },
    };
    return buildLayout(renderRichText(rawHTML, options));
};
function buildLayout(elements, i) {
    const leftColContent = elements.filter(
        (e) => e?.type === "h2" && e.props.className === "topic-title"
    );
    let innerVal = leftColContent.map((e) => e.props.children);
    let innerValue = innerVal.map((e) => e);

    let leftContent = elements
        .filter((el, i) => el?.type === "h2")
        .map((e) => {
            // const handleClass = (e) => {
            //     const chapters = document.querySelectorAll(".title-wrap a");
            //     chapters.forEach((el) => el.classList.remove("active"));

            //     e.target.classList.add("active");
            // };

            return (
                <a
                    href={"#" + slugify(`${e.props.children}`, { remove: /[%]/g })}
                    // onClick={handleClass}
                    className="chapter"
                >
                    {e.props.children[0]}
                </a>
            );
        });
    let rightContent = elements.map((el, i) => {
        if (el?.type === "h2") {
            return React.createElement(
                "h2",

                {
                    id: slugify(`${el.props.children}`, { remove: /[%]/g }),
                    className: "text-h2 right-el text-clr-primary text-fw-medium",
                },
                el.props.children
            );
        }
        return el;
    });

    const header = React.createElement(
        "div",
        { className: "blog-row__left-header text-p1 text-clr-primary text-fw-medium" },
        "Chapters"
    );
    const titlewrap = React.createElement(
        "div",
        {
            className: "title-wrap",
        },

        leftContent
    );
    const leftColWrap = React.createElement(
        "div",
        {
            className: "wrapper",
        },
        [header, titlewrap]
    );

    const innerlft = React.createElement(
        "div",
        { className: "blog-row__left" },

        leftColWrap
    );
    const content = React.createElement("div", { className: "blog-row__right" }, rightContent);
    const wrapper = React.createElement(
        "div",
        { className: "blog-row", key: i },
        innerlft,
        content
    );

    return wrapper;
}
export default useResolveBlogText;
