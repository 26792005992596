import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import useResolveBlogText from "../hooks/useResolveBlogText";
import useResolveNonChapterBlogText from "../hooks/useResolveNonChapterBlogText";
import { GatsbyImage } from "gatsby-plugin-image";
import FilterCategory from "../components/FilterCategory";
import EmailUsBlock from "../components/EmailUsBlock";
import BlogCtaBanner from "../components/BlogCtaBanner";
import { Link } from "gatsby";
import Progressbar from "../components/Progressbar";
import Seo from "../components/seo.jsx";
import { useEffect } from "react";
import ogIMage from "../images/og.png";

const BlogDetail = ({ data, location, pageContext }) => {
    const schema = [
        {
            "@context": "https://schema.org",
            "@type": "Article",
            mainEntityOfPage: {
                "@type": "WebPage",
                "@id": `https://sedintechnologies.com/blog/${data.PageData.slug}`,
            },
            headline: `${data.PageData.metaTitle}`,
            description: `${data.PageData.metaDescription.metaDescription}`,
            image: `${data.PageData.blogImage.gatsbyImageData?.images.fallback.src}`,
            author: {
                "@type": "Person",
                name: `${data.PageData.author}`,
            },
            publisher: {
                "@type": "Organization",
                name: "Sedin",
                logo: {
                    "@type": "ImageObject",
                    url: `https://sedintechnologies.com${ogIMage}`,
                },
            },
            datePublished: `${data.PageData.createdAt}`,
            dateModified: `${data.PageData.updatedAt}`,
        },
    ];

    const blogText = useResolveBlogText(data.PageData.blogcontent);
    const nonChapterBlogText = useResolveNonChapterBlogText(data.PageData.blogcontent);
    // const authorImgArr = data.PageData.authorInfo?.map((el) => el.authorImage.gatsbyImageData);
    const blogOgImage = data.PageData.blogImage.gatsbyImageData.images?.fallback.src;

    useEffect(() => {
        document.querySelectorAll(".chapter").forEach((anchor) => {
            anchor.addEventListener("click", function (e) {
                e.preventDefault();

                document.querySelector(this.getAttribute("href")).scrollIntoView({
                    behavior: "smooth",
                });
            });
        });
        const leftChapters = document.querySelectorAll(".chapter");
        const rightColEl = document.querySelectorAll(".right-el");
        /******************Observer code *********/
        const observer = new IntersectionObserver(
            function (entries, observer) {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        leftChapters.forEach((el) => {
                            el.classList.remove("active");
                            if (entry.target.innerHTML === el.innerHTML) {
                                el.classList.add("active");
                            }
                        });
                    }
                });
            }
            // {
            //     rootMargin: "-50% 0% -50% 0%",
            // }
        );
        rightColEl.forEach((card) => observer.observe(card));
    }, []);

    return (
        <Layout>
            <Seo
                title={data.PageData.metaTitle}
                description={data.PageData.metaDescription.metaDescription}
                schemaMarkup={schema}
                url={location.href}
                image={blogOgImage}
            />
            <>
                <Progressbar />
                <section className="blog-detail">
                    <div className="container container--first-v3">
                        <div className="blog-detail__breadcrumbs gap-2x  text-fw-normal">
                            <Link
                                className="text-decor-none text-clr-secondary text-fw-regular text-p4"
                                to={`/insights/blog/`}
                            >
                                Blog
                            </Link>
                            <p>/</p>
                            <Link
                                className="text-decor-none text-clr-primary text-fw-regular text-p4"
                                to={`/blog/${pageContext.category.slug.toString()}/`}
                            >
                                {pageContext.category.title.toString()}
                            </Link>
                        </div>
                        <h1 className="text-h1 text-primary-clr text-fw-medium gap-2x">
                            {data.PageData.title}
                        </h1>
                        <div className="blog-detail__info flex flex-ai-c">
                            <p className="text-p2 text-clr-primary text-fw-regular ">
                                {data.PageData.author}
                            </p>
                            <p className="text-p2 text-clr-primary text-fw-regular ">
                                {data.PageData.readTime}
                            </p>
                        </div>
                        <GatsbyImage
                            image={data.PageData.blogImage.gatsbyImageData}
                            alt={data.PageData.title}
                            loading="lazy"
                        />
                        {data.PageData.chapters ? (
                            <div>
                                <div>{blogText}</div>
                            </div>
                        ) : (
                            <>
                                <div className="non-chapter-blog-wrapper">
                                    <div className="non-chapter-blog">{nonChapterBlogText}</div>
                                    <div className="non-chapter-blog-contact-hero">
                                        <BlogCtaBanner
                                            title={data.PageData.blogCtaInfo.header}
                                            ctaLink={data.PageData.blogCtaInfo.ctaSlug}
                                            ctaText={data.PageData.blogCtaInfo.ctaText}
                                        />
                                    </div>
                                    <div>
                                        <h2 className="text-h2 text-clr-primary text-fw-medium ">
                                            Tags
                                        </h2>
                                        <FilterCategory uniqueCategory={data.PageData.tags} tags />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </section>
                {data.PageData.chapters && (
                    <>
                        <section className="author-info">
                            <div className="container">
                                <div className="author-info__wrap">
                                    <div className="empty-block"></div>
                                    <div className="empty-block"></div>
                                    {/* <BlogAuthorInfo
                                        title={data.PageData.authorInfo.map((el) => el.name)}
                                        description={data.PageData.authorInfo.map((el) => el.description)}
                                        authorImage={authorInfoImg}
                                    /> */}
                                    <div className="empty-block"></div>
                                    <BlogCtaBanner
                                        title={data.PageData.blogCtaInfo.header}
                                        ctaLink={data.PageData.blogCtaInfo.ctaSlug}
                                        ctaText={data.PageData.blogCtaInfo.ctaText}
                                    />
                                </div>
                            </div>
                            <div></div>
                        </section>
                        <section className="blog-tags">
                            <div className="container">
                                <div className="blog-tags__wrap">
                                    <div></div>
                                    <div>
                                        <h2 className="text-h2 text-clr-primary text-fw-medium ">
                                            Tags
                                        </h2>
                                        <FilterCategory uniqueCategory={data.PageData.tags} tags />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                )}
                {/* {data.PageData.youMayAlsoLike ? (
                <section className="related-blogs">
                    <div className="container container--mid-v2">
                        <BlogGrid relatedBlogs={data.PageData.youMayAlsoLike} />
                    </div>
                </section>
            ) : (
                ""
            )} */}
                <section className="email">
                    <div className="container container--last-v2">
                        <EmailUsBlock />
                    </div>
                </section>
            </>
        </Layout>
    );
};

export default BlogDetail;

export const query = graphql`
    query BlogDetailPageQuery($id: String) {
        PageData: contentfulBlogs(id: { eq: $id }) {
            id
            title
            slug
            readTime
            author
            chapters
            updatedAt(formatString: "YYYY-MM-DD")
            createdAt(formatString: "YYYY-MM-DD")
            blogImage {
                gatsbyImageData(quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
            }
            tags {
                categoryType
                slug
            }
            categories {
                categoryType
                slug
            }
            blogcontent {
                raw
                references {
                    file {
                        contentType
                        url
                        fileName
                    }
                    ... on ContentfulAsset {
                        __typename
                        contentful_id
                        gatsbyImageData(placeholder: BLURRED, quality: 100)
                        title
                    }
                }
            }
            authorInfo {
                authorImage {
                    gatsbyImageData(
                        quality: 100
                        placeholder: BLURRED
                        layout: CONSTRAINED
                        width: 168
                    )
                }
                name
                description {
                    description
                }
            }
            blogCtaInfo {
                ctaText
                header
                ctaSlug
            }
            metaTitle
            metaDescription {
                metaDescription
            }
            metaTitle
            metaDescription {
                metaDescription
            }
        }
    }
`;
