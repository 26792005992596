import React from "react";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Lottie from "react-lottie";
import InViewLogoAnimationController from "../components/InViewLogoAnimationController";

const useResolveNonChapterBlogText = (rawHTML) => {
    const options = {
        renderNode: {
            [INLINES.HYPERLINK]: ({ data }, children) => {
                if (data.uri.includes("youtube") || data.uri.includes("youtu.be")) {
                    let videoId = data.uri.substring(data.uri.lastIndexOf("/") + 1);
                    return (
                        <InViewLogoAnimationController animationName={"play"}>
                            <iframe
                                width="100%"
                                height="360"
                                src={`https://www.youtube.com/embed/${videoId}`}
                                frameBorder="0"
                                title={`youtube embed ${videoId}`}
                            ></iframe>
                        </InViewLogoAnimationController>
                    );
                }
                return (
                    <a href={data.uri} target="_blank" rel="noreferrer">
                        {children}
                    </a>
                );
            },
            [BLOCKS.HEADING_1]: (node, children) => {
                return <h1>{children}</h1>;
            },
            [BLOCKS.HEADING_2]: (node, children) => {
                return <h2 className="text-h2 topic-title text-fw-medium">{children}</h2>;
            },
            [BLOCKS.HEADING_3]: (node, children) => {
                return <h3 className="text-h3 text-clr-primary text-fw-medium">{children}</h3>;
            },
            [BLOCKS.HEADING_4]: (node, children) => {
                return <h4 className=" text-clr-primary text-fw-medium">{children}</h4>;
            },
            [BLOCKS.HEADING_5]: (node, children) => {
                return <h5 className=" text-clr-primary text-fw-medium">{children}</h5>;
            },
            [BLOCKS.HEADING_6]: (node, children) => {
                return <h6 className=" text-clr-primary text-fw-medium">{children}</h6>;
            },
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                const { gatsbyImageData, title, file } = node.data.target;

                if (file.contentType === "video/mp4")
                    return (
                        <InViewLogoAnimationController animationName={"play"}>
                            <video controls>
                                <source src={file.url} type={file.contentType} />
                            </video>
                        </InViewLogoAnimationController>
                    );

                if (file.contentType === "application/json")
                    return (
                        <div className="lottie-player-wrap">
                            <Lottie
                                className="lottie-player"
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    path: file.url,
                                    rendererSettings: {
                                        preserveAspectRatio: "xMidYMid slice",
                                    },
                                }}
                            />
                        </div>
                    );

                return <GatsbyImage image={getImage(gatsbyImageData)} alt={title} />;
            },
        },
    };
    return renderRichText(rawHTML, options);
};

export default useResolveNonChapterBlogText;
